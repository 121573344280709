import React, {Component} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Row} from 'reactstrap';
import logo from './logo.png';
import './App.css';
import CrawledPage from './CrawledPage'
import LoadingIndicator from "./LoadingIndicator";
import CrawledPageError from "./CrawledPageError";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        const crawlData = {urls: [this.state.url]};
        this.setState({crawledPage: null, errorMessage: null, isLoading: true});
        await fetch('/api/crawl', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(crawlData)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response
            }
        }).then(data => {
            if (data[0].errorMessage != null) {
                throw data[0]
            } else {
                this.setState({crawledPage: data[0], isLoading: false})
            }
        }).catch(error => {
            if (error.errorMessage != null) {
                this.setState({errorMessage: error.errorMessage, isLoading: false})
            } else {
                error.text().then(errorMessage => {
                    if (errorMessage && errorMessage.length > 0) {
                        let message = JSON.parse(errorMessage).message;
                        this.setState({errorMessage: message, isLoading: false})
                    } else {
                        this.setState({errorMessage: "Unknown problem.", isLoading: false})
                    }

                })
            }
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({url: value});
    }

    render() {
        const urlData = this.state;

        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                </header>
                <div className="App-intro">
                    <h2>SocialBee Crawler</h2>
                    <Container>
                        <Row>
                            <Col xs="3">&nbsp;</Col>
                            <Col xs="6">
                                <Form onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Input onChange={this.handleChange} type="url" name="url" id="url"
                                               placeholder="Enter a link you'd like to crawl"/>
                                    </FormGroup>
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </Col>
                            <Col xs="3">&nbsp;</Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <LoadingIndicator isLoading={urlData.isLoading}/>
                    <CrawledPageError errorMessage={urlData.errorMessage} isLoading={urlData.isLoading}/>
                    <CrawledPage page={urlData.crawledPage} isLoading={urlData.isLoading}/>
                </div>
            </div>
        );
    }
}

export default App;
