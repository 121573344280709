import React from 'react';
import logoSmall from './logo-small.png';
import './App.css';


class LoadingIndicator extends React.Component {

    render() {
        if(this.props.isLoading) {
            return (
                <div>
                    <img src={logoSmall} className="App-logo-spin" alt="Loading" />
                </div>
            );
        } else {
            return null;
        }

    }
}

export default LoadingIndicator
