import React from 'react';
import {Table, Container, Row, Col} from 'reactstrap';
import './App.css';


class CrawledPage extends React.Component {

    render() {
        if (this.props.page && !this.props.isLoading) {
            return (
                <Container>
                    <Row>
                        <Col xs="1">&nbsp;</Col>
                        <Col xs="10">
                            <Table striped hover responsive>
                                <thead>
                                <tr>
                                    <th style={{width: '30%'}}>Property</th>
                                    <th style={{width: '70%'}}>Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">Original Link</th>
                                    <td>{this.props.page.originalLink}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Final Link</th>
                                    <td>{this.props.page.finalLink}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Type</th>
                                    <td>{this.props.page.pageType}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Title</th>
                                    <td>{this.props.page.title}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Description</th>
                                    <td>{this.props.page.description}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Images</th>
                                    <td>
                                        {this.props.page.images.map(image =>
                                            <div key={image.src}>
                                                {image.imageExtractionType} - score: {image.confidenceScore} (W:{image.width},H:{image.height},S:{image.bytes})
                                                <img src={image.src} alt={image.imageExtractionType} className="App-crawled-image" />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Content</th>
                                    <td>{this.props.page.content}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Keywords</th>
                                    <td>{this.props.page.keywords}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Publish Date</th>
                                    <td>{this.props.page.publishDate}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs="1">&nbsp;</Col>
                    </Row>
                </Container>
            );
        } else {
            return null;
        }

    }
}

export default CrawledPage
