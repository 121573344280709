import React from 'react';
import {Alert} from 'reactstrap';
import './App.css';


class CrawledPageError extends React.Component {

    render() {
        if (this.props.errorMessage != null && !this.props.isLoading) {
            return (
                <div>
                    <Alert color="danger">
                        {this.props.errorMessage}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }

    }
}

export default CrawledPageError
